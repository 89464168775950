package com.zegreatrob.coupling.action.secret

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class DeleteSecretCommandWrapper(
  override val action: DeleteSecretCommand,
) : SuspendAction<DeleteSecretCommand.Dispatcher, VoidResult>,
    ActionWrapper<DeleteSecretCommand.Dispatcher, DeleteSecretCommand> {
  override val dispatcherType: KClass<DeleteSecretCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.secret.DeleteSecretCommand.Dispatcher::class

  override suspend fun execute(dispatcher: DeleteSecretCommand.Dispatcher): VoidResult =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: DeleteSecretCommand.Dispatcher,
    action: DeleteSecretCommand): VoidResult = execute(dispatcher,
    ::DeleteSecretCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<DeleteSecretCommand.Dispatcher>,
    action: DeleteSecretCommand): VoidResult =
    cannon.fire(::DeleteSecretCommandWrapper.invoke(action))

public suspend fun ActionCannon<DeleteSecretCommand.Dispatcher>.fire(action: DeleteSecretCommand):
    VoidResult = fire(::DeleteSecretCommandWrapper.invoke(action))

public fun DeleteSecretCommand.wrap(): DeleteSecretCommandWrapper =
    ::DeleteSecretCommandWrapper.invoke(this)

public operator fun <R> ((DeleteSecretCommandWrapper) -> R).invoke(action: DeleteSecretCommand): R =
    this(::DeleteSecretCommandWrapper.invoke(action))

public fun <R> call(function: (DeleteSecretCommandWrapper) -> R, action: DeleteSecretCommand): R =
    function(::DeleteSecretCommandWrapper.invoke(action))

public fun <R> DeleteSecretCommand.let(block: (DeleteSecretCommandWrapper) -> R): R =
    block(::DeleteSecretCommandWrapper.invoke(this))
