package com.zegreatrob.coupling.action.pairassignmentdocument

import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class NextPlayerActionWrapper(
  override val action: NextPlayerAction,
) : SuspendAction<NextPlayerAction.Dispatcher<*>, PairCandidateReport>,
    ActionWrapper<NextPlayerAction.Dispatcher<*>, NextPlayerAction> {
  override val dispatcherType: KClass<NextPlayerAction.Dispatcher<*>>
    get() = com.zegreatrob.coupling.action.pairassignmentdocument.NextPlayerAction.Dispatcher::class

  override suspend fun execute(dispatcher: NextPlayerAction.Dispatcher<*>): PairCandidateReport =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: NextPlayerAction.Dispatcher<*>,
    action: NextPlayerAction): PairCandidateReport = execute(dispatcher,
    ::NextPlayerActionWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<NextPlayerAction.Dispatcher<*>>,
    action: NextPlayerAction): PairCandidateReport =
    cannon.fire(::NextPlayerActionWrapper.invoke(action))

public suspend fun ActionCannon<NextPlayerAction.Dispatcher<*>>.fire(action: NextPlayerAction):
    PairCandidateReport = fire(::NextPlayerActionWrapper.invoke(action))

public fun NextPlayerAction.wrap(): NextPlayerActionWrapper = ::NextPlayerActionWrapper.invoke(this)

public operator fun <R> ((NextPlayerActionWrapper) -> R).invoke(action: NextPlayerAction): R =
    this(::NextPlayerActionWrapper.invoke(action))

public fun <R> call(function: (NextPlayerActionWrapper) -> R, action: NextPlayerAction): R =
    function(::NextPlayerActionWrapper.invoke(action))

public fun <R> NextPlayerAction.let(block: (NextPlayerActionWrapper) -> R): R =
    block(::NextPlayerActionWrapper.invoke(this))
