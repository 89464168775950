package com.zegreatrob.coupling.action

import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class GrantSlackAccessCommandWrapper(
  override val action: GrantSlackAccessCommand,
) : SuspendAction<GrantSlackAccessCommand.Dispatcher, VoidResult>,
    ActionWrapper<GrantSlackAccessCommand.Dispatcher, GrantSlackAccessCommand> {
  override val dispatcherType: KClass<GrantSlackAccessCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.GrantSlackAccessCommand.Dispatcher::class

  override suspend fun execute(dispatcher: GrantSlackAccessCommand.Dispatcher): VoidResult =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: GrantSlackAccessCommand.Dispatcher,
    action: GrantSlackAccessCommand): VoidResult = execute(dispatcher,
    ::GrantSlackAccessCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<GrantSlackAccessCommand.Dispatcher>,
    action: GrantSlackAccessCommand): VoidResult =
    cannon.fire(::GrantSlackAccessCommandWrapper.invoke(action))

public suspend
    fun ActionCannon<GrantSlackAccessCommand.Dispatcher>.fire(action: GrantSlackAccessCommand):
    VoidResult = fire(::GrantSlackAccessCommandWrapper.invoke(action))

public fun GrantSlackAccessCommand.wrap(): GrantSlackAccessCommandWrapper =
    ::GrantSlackAccessCommandWrapper.invoke(this)

public operator fun <R>
    ((GrantSlackAccessCommandWrapper) -> R).invoke(action: GrantSlackAccessCommand): R =
    this(::GrantSlackAccessCommandWrapper.invoke(action))

public fun <R> call(function: (GrantSlackAccessCommandWrapper) -> R,
    action: GrantSlackAccessCommand): R = function(::GrantSlackAccessCommandWrapper.invoke(action))

public fun <R> GrantSlackAccessCommand.let(block: (GrantSlackAccessCommandWrapper) -> R): R =
    block(::GrantSlackAccessCommandWrapper.invoke(this))
