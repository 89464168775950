package com.zegreatrob.coupling.action.player

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class DeletePlayerCommandWrapper(
  override val action: DeletePlayerCommand,
) : SuspendAction<DeletePlayerCommand.Dispatcher, VoidResult>,
    ActionWrapper<DeletePlayerCommand.Dispatcher, DeletePlayerCommand> {
  override val dispatcherType: KClass<DeletePlayerCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.player.DeletePlayerCommand.Dispatcher::class

  override suspend fun execute(dispatcher: DeletePlayerCommand.Dispatcher): VoidResult =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: DeletePlayerCommand.Dispatcher,
    action: DeletePlayerCommand): VoidResult = execute(dispatcher,
    ::DeletePlayerCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<DeletePlayerCommand.Dispatcher>,
    action: DeletePlayerCommand): VoidResult =
    cannon.fire(::DeletePlayerCommandWrapper.invoke(action))

public suspend fun ActionCannon<DeletePlayerCommand.Dispatcher>.fire(action: DeletePlayerCommand):
    VoidResult = fire(::DeletePlayerCommandWrapper.invoke(action))

public fun DeletePlayerCommand.wrap(): DeletePlayerCommandWrapper =
    ::DeletePlayerCommandWrapper.invoke(this)

public operator fun <R> ((DeletePlayerCommandWrapper) -> R).invoke(action: DeletePlayerCommand): R =
    this(::DeletePlayerCommandWrapper.invoke(action))

public fun <R> call(function: (DeletePlayerCommandWrapper) -> R, action: DeletePlayerCommand): R =
    function(::DeletePlayerCommandWrapper.invoke(action))

public fun <R> DeletePlayerCommand.let(block: (DeletePlayerCommandWrapper) -> R): R =
    block(::DeletePlayerCommandWrapper.invoke(this))
