package com.zegreatrob.coupling.action.party

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class SaveSlackIntegrationCommandWrapper(
  override val action: SaveSlackIntegrationCommand,
) : SuspendAction<SaveSlackIntegrationCommand.Dispatcher, VoidResult>,
    ActionWrapper<SaveSlackIntegrationCommand.Dispatcher, SaveSlackIntegrationCommand> {
  override val dispatcherType: KClass<SaveSlackIntegrationCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.party.SaveSlackIntegrationCommand.Dispatcher::class

  override suspend fun execute(dispatcher: SaveSlackIntegrationCommand.Dispatcher): VoidResult =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: SaveSlackIntegrationCommand.Dispatcher,
    action: SaveSlackIntegrationCommand): VoidResult = execute(dispatcher,
    ::SaveSlackIntegrationCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<SaveSlackIntegrationCommand.Dispatcher>,
    action: SaveSlackIntegrationCommand): VoidResult =
    cannon.fire(::SaveSlackIntegrationCommandWrapper.invoke(action))

public suspend
    fun ActionCannon<SaveSlackIntegrationCommand.Dispatcher>.fire(action: SaveSlackIntegrationCommand):
    VoidResult = fire(::SaveSlackIntegrationCommandWrapper.invoke(action))

public fun SaveSlackIntegrationCommand.wrap(): SaveSlackIntegrationCommandWrapper =
    ::SaveSlackIntegrationCommandWrapper.invoke(this)

public operator fun <R>
    ((SaveSlackIntegrationCommandWrapper) -> R).invoke(action: SaveSlackIntegrationCommand): R =
    this(::SaveSlackIntegrationCommandWrapper.invoke(action))

public fun <R> call(function: (SaveSlackIntegrationCommandWrapper) -> R,
    action: SaveSlackIntegrationCommand): R =
    function(::SaveSlackIntegrationCommandWrapper.invoke(action))

public fun <R> SaveSlackIntegrationCommand.let(block: (SaveSlackIntegrationCommandWrapper) -> R): R
    = block(::SaveSlackIntegrationCommandWrapper.invoke(this))
