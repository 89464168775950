package com.zegreatrob.coupling.action

import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class GrantDiscordAccessCommandWrapper(
  override val action: GrantDiscordAccessCommand,
) : SuspendAction<GrantDiscordAccessCommand.Dispatcher, VoidResult>,
    ActionWrapper<GrantDiscordAccessCommand.Dispatcher, GrantDiscordAccessCommand> {
  override val dispatcherType: KClass<GrantDiscordAccessCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.GrantDiscordAccessCommand.Dispatcher::class

  override suspend fun execute(dispatcher: GrantDiscordAccessCommand.Dispatcher): VoidResult =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: GrantDiscordAccessCommand.Dispatcher,
    action: GrantDiscordAccessCommand): VoidResult = execute(dispatcher,
    ::GrantDiscordAccessCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<GrantDiscordAccessCommand.Dispatcher>,
    action: GrantDiscordAccessCommand): VoidResult =
    cannon.fire(::GrantDiscordAccessCommandWrapper.invoke(action))

public suspend
    fun ActionCannon<GrantDiscordAccessCommand.Dispatcher>.fire(action: GrantDiscordAccessCommand):
    VoidResult = fire(::GrantDiscordAccessCommandWrapper.invoke(action))

public fun GrantDiscordAccessCommand.wrap(): GrantDiscordAccessCommandWrapper =
    ::GrantDiscordAccessCommandWrapper.invoke(this)

public operator fun <R>
    ((GrantDiscordAccessCommandWrapper) -> R).invoke(action: GrantDiscordAccessCommand): R =
    this(::GrantDiscordAccessCommandWrapper.invoke(action))

public fun <R> call(function: (GrantDiscordAccessCommandWrapper) -> R,
    action: GrantDiscordAccessCommand): R =
    function(::GrantDiscordAccessCommandWrapper.invoke(action))

public fun <R> GrantDiscordAccessCommand.let(block: (GrantDiscordAccessCommandWrapper) -> R): R =
    block(::GrantDiscordAccessCommandWrapper.invoke(this))
