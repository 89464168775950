package com.zegreatrob.coupling.action

import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class ApplyBoostCommandWrapper(
  override val action: ApplyBoostCommand,
) : SuspendAction<ApplyBoostCommand.Dispatcher, ApplyBoostCommand.Result>,
    ActionWrapper<ApplyBoostCommand.Dispatcher, ApplyBoostCommand> {
  override val dispatcherType: KClass<ApplyBoostCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.ApplyBoostCommand.Dispatcher::class

  override suspend fun execute(dispatcher: ApplyBoostCommand.Dispatcher): ApplyBoostCommand.Result =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: ApplyBoostCommand.Dispatcher,
    action: ApplyBoostCommand): ApplyBoostCommand.Result = execute(dispatcher,
    ::ApplyBoostCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<ApplyBoostCommand.Dispatcher>,
    action: ApplyBoostCommand): ApplyBoostCommand.Result =
    cannon.fire(::ApplyBoostCommandWrapper.invoke(action))

public suspend fun ActionCannon<ApplyBoostCommand.Dispatcher>.fire(action: ApplyBoostCommand):
    ApplyBoostCommand.Result = fire(::ApplyBoostCommandWrapper.invoke(action))

public fun ApplyBoostCommand.wrap(): ApplyBoostCommandWrapper =
    ::ApplyBoostCommandWrapper.invoke(this)

public operator fun <R> ((ApplyBoostCommandWrapper) -> R).invoke(action: ApplyBoostCommand): R =
    this(::ApplyBoostCommandWrapper.invoke(action))

public fun <R> call(function: (ApplyBoostCommandWrapper) -> R, action: ApplyBoostCommand): R =
    function(::ApplyBoostCommandWrapper.invoke(action))

public fun <R> ApplyBoostCommand.let(block: (ApplyBoostCommandWrapper) -> R): R =
    block(::ApplyBoostCommandWrapper.invoke(this))
