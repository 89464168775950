package com.zegreatrob.coupling.action

import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class SpinCommandWrapper(
  override val action: SpinCommand,
) : SuspendAction<SpinCommand.Dispatcher, SpinCommand.Result>,
    ActionWrapper<SpinCommand.Dispatcher, SpinCommand> {
  override val dispatcherType: KClass<SpinCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.SpinCommand.Dispatcher::class

  override suspend fun execute(dispatcher: SpinCommand.Dispatcher): SpinCommand.Result =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: SpinCommand.Dispatcher, action: SpinCommand):
    SpinCommand.Result = execute(dispatcher, ::SpinCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<SpinCommand.Dispatcher>, action: SpinCommand):
    SpinCommand.Result = cannon.fire(::SpinCommandWrapper.invoke(action))

public suspend fun ActionCannon<SpinCommand.Dispatcher>.fire(action: SpinCommand):
    SpinCommand.Result = fire(::SpinCommandWrapper.invoke(action))

public fun SpinCommand.wrap(): SpinCommandWrapper = ::SpinCommandWrapper.invoke(this)

public operator fun <R> ((SpinCommandWrapper) -> R).invoke(action: SpinCommand): R =
    this(::SpinCommandWrapper.invoke(action))

public fun <R> call(function: (SpinCommandWrapper) -> R, action: SpinCommand): R =
    function(::SpinCommandWrapper.invoke(action))

public fun <R> SpinCommand.let(block: (SpinCommandWrapper) -> R): R =
    block(::SpinCommandWrapper.invoke(this))
