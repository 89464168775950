package com.zegreatrob.coupling.action.pairassignmentdocument

import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass
import kotools.types.collection.NotEmptyList

@JvmInline
public value class CreatePairCandidateReportListActionWrapper(
  override val action: CreatePairCandidateReportListAction,
) :
    SuspendAction<CreatePairCandidateReportListAction.Dispatcher<*>, NotEmptyList<PairCandidateReport>>,
   
    ActionWrapper<CreatePairCandidateReportListAction.Dispatcher<*>, CreatePairCandidateReportListAction>
    {
  override val dispatcherType: KClass<CreatePairCandidateReportListAction.Dispatcher<*>>
    get() =
        com.zegreatrob.coupling.action.pairassignmentdocument.CreatePairCandidateReportListAction.Dispatcher::class

  override suspend fun execute(dispatcher: CreatePairCandidateReportListAction.Dispatcher<*>):
      NotEmptyList<PairCandidateReport> = dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: CreatePairCandidateReportListAction.Dispatcher<*>,
    action: CreatePairCandidateReportListAction): NotEmptyList<PairCandidateReport> =
    execute(dispatcher, ::CreatePairCandidateReportListActionWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<CreatePairCandidateReportListAction.Dispatcher<*>>,
    action: CreatePairCandidateReportListAction): NotEmptyList<PairCandidateReport> =
    cannon.fire(::CreatePairCandidateReportListActionWrapper.invoke(action))

public suspend
    fun ActionCannon<CreatePairCandidateReportListAction.Dispatcher<*>>.fire(action: CreatePairCandidateReportListAction):
    NotEmptyList<PairCandidateReport> =
    fire(::CreatePairCandidateReportListActionWrapper.invoke(action))

public fun CreatePairCandidateReportListAction.wrap(): CreatePairCandidateReportListActionWrapper =
    ::CreatePairCandidateReportListActionWrapper.invoke(this)

public operator fun <R>
    ((CreatePairCandidateReportListActionWrapper) -> R).invoke(action: CreatePairCandidateReportListAction):
    R = this(::CreatePairCandidateReportListActionWrapper.invoke(action))

public fun <R> call(function: (CreatePairCandidateReportListActionWrapper) -> R,
    action: CreatePairCandidateReportListAction): R =
    function(::CreatePairCandidateReportListActionWrapper.invoke(action))

public fun <R>
    CreatePairCandidateReportListAction.let(block: (CreatePairCandidateReportListActionWrapper) -> R):
    R = block(::CreatePairCandidateReportListActionWrapper.invoke(this))
