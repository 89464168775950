package com.zegreatrob.coupling.action.party

import com.zegreatrob.coupling.action.VoidResult
import com.zegreatrob.testmints.action.ActionCannon
import com.zegreatrob.testmints.action.ActionPipe
import com.zegreatrob.testmints.action.ActionWrapper
import com.zegreatrob.testmints.action.async.SuspendAction
import kotlin.jvm.JvmInline
import kotlin.reflect.KClass

@JvmInline
public value class SavePartyCommandWrapper(
  override val action: SavePartyCommand,
) : SuspendAction<SavePartyCommand.Dispatcher, VoidResult>,
    ActionWrapper<SavePartyCommand.Dispatcher, SavePartyCommand> {
  override val dispatcherType: KClass<SavePartyCommand.Dispatcher>
    get() = com.zegreatrob.coupling.action.party.SavePartyCommand.Dispatcher::class

  override suspend fun execute(dispatcher: SavePartyCommand.Dispatcher): VoidResult =
      dispatcher.perform(action)
}

public suspend fun ActionPipe.execute(dispatcher: SavePartyCommand.Dispatcher,
    action: SavePartyCommand): VoidResult = execute(dispatcher,
    ::SavePartyCommandWrapper.invoke(action))

public suspend fun perform(cannon: ActionCannon<SavePartyCommand.Dispatcher>,
    action: SavePartyCommand): VoidResult = cannon.fire(::SavePartyCommandWrapper.invoke(action))

public suspend fun ActionCannon<SavePartyCommand.Dispatcher>.fire(action: SavePartyCommand):
    VoidResult = fire(::SavePartyCommandWrapper.invoke(action))

public fun SavePartyCommand.wrap(): SavePartyCommandWrapper = ::SavePartyCommandWrapper.invoke(this)

public operator fun <R> ((SavePartyCommandWrapper) -> R).invoke(action: SavePartyCommand): R =
    this(::SavePartyCommandWrapper.invoke(action))

public fun <R> call(function: (SavePartyCommandWrapper) -> R, action: SavePartyCommand): R =
    function(::SavePartyCommandWrapper.invoke(action))

public fun <R> SavePartyCommand.let(block: (SavePartyCommandWrapper) -> R): R =
    block(::SavePartyCommandWrapper.invoke(this))
